import React, { useCallback, useState } from "react"
import { insertMember } from '../../reducks/members/operations'
import { useDispatch } from 'react-redux'
import { JsDir } from "../../common"
import importScript from '../../components/hooks/ImportScript'


const ItemList = (props) =>
{
  // svg操作のjsの読み込み
  importScript(JsDir + '/ajaxzip3.js')
  const dispatch = useDispatch()

  //姓の入力
  const [familyNameValue, setFamilyNameValue] = useState('')
  const inputFamilyNameValue = useCallback((event) =>
  {
    setFamilyNameValue(event.target.value)
  }, [setFamilyNameValue])

  //名の入力
  const [firstNameValue, setFirstNameValue] = useState('')
  const inputFirstNameValue = useCallback((event) =>
  {
    setFirstNameValue(event.target.value)
  }, [setFirstNameValue])

  //セイの入力
  const [familyNameFuriganaValue, setFamilyNameFuriganaValue] = useState('')
  const inputFamilyNameFuriganaValue = useCallback((event) =>
  {
    setFamilyNameFuriganaValue(event.target.value)
  }, [setFamilyNameFuriganaValue])

  //メイの入力
  const [firstNameFuriganaValue, setFirstNameFuriganaValue] = useState('')
  const inputFirstNameFuriganaValue = useCallback((event) =>
  {
    setFirstNameFuriganaValue(event.target.value)
  }, [setFirstNameFuriganaValue])

  //パスワードの入力
  const [passwordValue, setPasswordValue] = useState('')
  const inputPasswordValue = useCallback((event) =>
  {
    setPasswordValue(event.target.value)
  }, [setPasswordValue])

  //パスワード（確認用）の入力
  const [rePasswordValue, setRePasswordValue] = useState('')
  const inputRePasswordValue = useCallback((event) =>
  {
    setRePasswordValue(event.target.value)
  }, [setRePasswordValue])

  //生年月日の入力
  const [birthdayValue, setBirthdayValue] = useState('')
  const inputBirthdayValue = useCallback((event) =>
  {
    setBirthdayValue(event.target.value)
  }, [setBirthdayValue])

  //郵便番号の入力
  const [postalCodeValue, setPostalCodeValue] = useState('')
  const inputPostalCodeValue = useCallback((event) =>
  {
    setPostalCodeValue(event.target.value)
  }, [setPostalCodeValue])

  //住所の入力
  const [addressValue, setAddressValue] = useState('')
  const inputAddressValue = useCallback((event) =>
  {
    setAddressValue(event.target.value)
  }, [setAddressValue])

  //電話番号の入力
  const [telnumberValue, setTelnumberValue] = useState('')
  const inputTelnumberValue = useCallback((event) =>
  {
    setTelnumberValue(event.target.value)
  }, [setTelnumberValue])

  //メールアドレスの入力
  const [mailAddressValue, setMailAddressValue] = useState('')
  const inputMailAddressValue = useCallback((event) =>
  {
    setMailAddressValue(event.target.value)
  }, [setMailAddressValue])

  //メルマガの受信の入力
  const [mailMagazineFlagValue, setMailMagazineFlagValue] = useState('1')
  const inputMailMagazineFlagValue = useCallback((event) =>
  {
    setMailMagazineFlagValue(event.target.value)
  }, [setMailMagazineFlagValue])


  //登録ボタン押下時の処理
  const sendFormData = () =>
  {
    //form情報の取得
    const formElement = document.getElementById('add_form')
    const formData = new FormData(formElement);

    //formkeyの追加
    formData.append('formkey','insertkey')
    dispatch(insertMember(formData))
  }

  //住所自動入力
  const {AjaxZip3} = window
  const postalAutoInput = ()=>
  {
    AjaxZip3.zip2addr('postal_code','','address','address')
    setTimeout(()=>{
      const addressElement = document.getElementsByName('address')
      const addressValue = addressElement[0].value
      setAddressValue(addressValue)
    },100)
  }

  return(
    <main id="admin_add_page">
      <h2 className="page_title"><span>会員新規登録</span></h2>
      <section className="box_type_1">
        <div className="title_area">
          <h3>会員情報</h3>
        </div>
        <div className="content_area">
          <form encType="multipart/form-data" method="post" id="add_form" onSubmit={(e)=>e.preventDefault()}>
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th>氏名</th>
                  <td className="flex">
                    <span className="header">姓</span>
                    <input
                      type = "text"
                      name = {'family_name'}
                      className = ""
                      value = {familyNameValue}
                      onChange = {inputFamilyNameValue}
                    />
                    <br/>
                    <span className="header">名</span>
                    <input
                      type = "text"
                      name = {'first_name'}
                      value = {firstNameValue}
                      onChange = {inputFirstNameValue}
                    />
                  </td>
                </tr>
                <tr>
                  <th>フリガナ</th>
                  <td className="flex">
                  <span className="header">セイ</span>
                    <input
                      type = "text"
                      name = {'family_name_furigana'}
                      value = {familyNameFuriganaValue}
                      onChange = {inputFamilyNameFuriganaValue}
                    />
                    <br/>
                    <span className="header">メイ</span>
                    <input
                      type = "text"
                      name = {'first_name_furigana'}
                      value = {firstNameFuriganaValue}
                      onChange = {inputFirstNameFuriganaValue}
                    />
                  </td>
                </tr>
                <tr>
                  <th>パスワード</th>
                  <td>
                    <input
                      type = "text"
                      name = {'password'}
                      value = {passwordValue}
                      onChange = {inputPasswordValue}
                    />
                    <br/>
                    <input
                      type = "text"
                      name = {'re_password'}
                      value = {rePasswordValue}
                      onChange = {inputRePasswordValue}
                      placeholder = {'確認のためにもう一度入力してください'}
                    />
                  </td>
                </tr>
                <tr>
                  <th>生年月日</th>
                  <td className="birthday_box">
                    <input
                      type = "text"
                      name = "birthday"
                      value = {birthdayValue}
                      onChange = {inputBirthdayValue}
                    />
                    <span>※西暦で入力してください</span>
                  </td>
                </tr>
                <tr>
                  <th>郵便番号</th>
                  <td>
                    <input
                      type = "text"
                      name = "postal_code"
                      value = {postalCodeValue}
                      onChange = {inputPostalCodeValue}
                    />
                    <button onClick={()=>postalAutoInput()}>郵便番号から検索</button>
                  </td>
                </tr>
                <tr>
                  <th>住所</th>
                  <td>
                    <input
                      type = "text"
                      name = "address"
                      value = {addressValue}
                      onChange = {inputAddressValue}
                    />
                  </td>
                </tr>
                <tr>
                  <th>電話番号</th>
                  <td>
                    <input
                      type = "text"
                      name = "telnumber"
                      value = {telnumberValue}
                      onChange = {inputTelnumberValue}
                    />
                  </td>
                </tr>
                <tr>
                  <th>メールアドレス</th>
                  <td>
                    <input
                      type = "text"
                      name = "mail_address"
                      value = {mailAddressValue}
                      onChange = {inputMailAddressValue}
                    />
                  </td>
                </tr>
                <tr>
                  <th>メルマガ配信希望</th>
                  <td className="mail_magazine_flag_box">
                  <label className="radio_btn_label">
                    <input
                      type="radio"
                      name="mail_magazine_flag"
                      value={1}
                      checked = {mailMagazineFlagValue === '1'}
                      onChange = {inputMailMagazineFlagValue}
                    />希望する
                  </label>
                  <br/>
                  <label className="radio_btn_label">
                    <input
                      type="radio"
                      name="mail_magazine_flag"
                      value={0}
                      checked = {mailMagazineFlagValue === '0'}
                      onChange = {inputMailMagazineFlagValue}
                    />希望しない
                  </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
        <div className="button_area">
          <button id = "insert_btn" onClick={()=>sendFormData()}>登録</button>
        </div>
      </section>
    </main>
  )
}

export default ItemList