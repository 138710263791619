import React, { useState } from "react"
import DocumentMeta from 'react-document-meta'
import { formSendConfirm } from '../../reducks/orders/operations'
import { useDispatch, useSelector } from 'react-redux'
import { BreadCrumb, OrderFlow, AddPostAddressForm } from '../../components'
import { SiteTitle } from './common'
import axios from 'axios'
import { ApiDir, RouteDir } from '../../common'
import { push } from "connected-react-router"

const CartList = () =>
{
  const dispatch = useDispatch()

  const postAddress = useSelector(state => state.orders.postAddress[0])

  const [familyName, setFamilyName] = useState(postAddress.family_name)
  const [firstName, setFirstName] = useState(postAddress.first_name)
  const [familyNameFurigana, setFamilyNameFurigana] = useState(postAddress.family_name_furigana)
  const [firstNameFurigana, setFirstNameFurigana] = useState(postAddress.first_name_furigana)
  const [postalCode, setpostalCode] = useState(postAddress.postal_code)
  const [address, setAddress] = useState(postAddress.address)
  const [telnumber, setTelnumber] = useState(postAddress.telnumber)



  const insertMyhouseAddress = () =>
  {
    //自宅情報の取得
    let params = new URLSearchParams()
    params.append('formkey','selectkey')
    axios.post(ApiDir+'/selectMyMemberInfo.php',params)
    .then(function(response){
      setFamilyName(response.data[0].family_name)
      setFirstName(response.data[0].first_name)
      setFamilyNameFurigana(response.data[0].family_name_furigana)
      setFirstNameFurigana(response.data[0].first_name_furigana)
      setpostalCode(response.data[0].postal_code)
      setAddress(response.data[0].address)
      setTelnumber(response.data[0].telnumber)
    })
    .catch(function(error){
      console.log(error)
      return
    })
  }

  const sendConfirmBtn = ()=>
  {
    const formElement = document.getElementById('insert_form')
    const formData = new FormData(formElement)
    dispatch(formSendConfirm(formData))
  }

  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: '買い物カゴ',
      href: '/mypage/cart_list'
    }
  ]


  return(
    <DocumentMeta {...meta}>
      <div id="add_order_post_address_page">
        <BreadCrumb
          floors = { Floors }
        />
        <main className="add_order_post_address_content">
          <h1>お届け先情報の追加</h1>
          <OrderFlow
            activeKey = {1}
          />
          <p className="introduction">
            「ご自宅の住所を入力する」を押すと登録されているご自宅の情報が入力できます。<br/>
            「お届けリストより入力する」を押すと登録した「お届けリスト」からお届け先を選択できます。<br/>
            会員情報に住所の登録がお済でない方、ご自宅以外にお届けされたい方は、下の入力欄に必要項目をご入力ください。
          </p>
          <div className="add_address_btn_area">
            <button onClick={()=>insertMyhouseAddress()}>ご自宅の住所を入力する</button>
            <button onClick={()=>dispatch(push(RouteDir+'/mypage/post_address_list'))}>お届けリストより入力する</button>
          </div>
          <p className="introduction">お届け先の情報をご入力ください。</p>
          <section className="form_type_1">
            <AddPostAddressForm
              familyName = {familyName}
              firstName = {firstName}
              familyNameFurigana = {familyNameFurigana}
              firstNameFurigana = {firstNameFurigana}
              postalCode = {postalCode}
              address = {address}
              telnumber = {telnumber}
            />
          </section>
          <div className="btn_area">
            <button className="order_post_info_link_btn" onClick={()=>dispatch(push(RouteDir+'/mypage/cart_list'))}>買い物かごに戻る</button>
            <button className='add_btn' onClick={()=>sendConfirmBtn()}>決定</button>
          </div>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default CartList