import React, { useEffect, useState } from 'react'

const style =
{
  display: 'none',
}

const ImageSelectyButton = (props) =>
{
  const [srcValue, setSrcValue] = useState('');
  const [deleteImageFlag, setDeleteImageFlag] = useState(true);

  useEffect(()=>{
    setSrcValue(props.imageSrc)
  },[props.imageSrc])

  //ボタンのひとつ前の兄弟要素のimgタグのsrcを変更（選択時のサムネイル変更）
  const ImageSelect = (e) =>
  {
    setSrcValue('')
    const prentElement = e.target.closest('.progress_key_element');
    prentElement.classList.add('progress');
    if (e.target.files && e.target.files[0]) {
      setDeleteImageFlag(true);
      //削除する画像idの格納
      if(props.imageSrc !== '')
      {
        props.setSelectImageDeleteFlag(true)
        const fileElement = document.getElementById('file_upload_' + props.id);
        let deleteImageIdArray = props.selectImageDeleteId;
        deleteImageIdArray.push(fileElement.dataset.id);
        props.setSelectImageDeleteId(deleteImageIdArray);
      }
      const reader = new FileReader();
      reader.onload = function(e) {
        document.getElementById(props.id).nextElementSibling.querySelector('img').setAttribute('src', e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setSrcValue(e.target.result)
    }
    prentElement.classList.remove('progress')
  }

  //画像の指定を削除
  const ImageDelete = (e,id) =>
  {
    if(props.setSelectImageDeleteFlag !== 'add')
    {
      props.setSelectImageDeleteFlag(true)
      const fileElement = document.getElementById('file_upload_' + id);
      fileElement.value = '';
      //削除する画像idの格納
      let deleteImageIdArray = props.selectImageDeleteId;
      deleteImageIdArray.push(fileElement.dataset.id);
      props.setSelectImageDeleteId(deleteImageIdArray);
    }
    const imgElement = document.getElementById(id + '_thumb_img');
    imgElement.setAttribute('src','');
    setDeleteImageFlag(false);
    setSrcValue('');
  }

  return (
    <>
    <button id={props.id} variant='contained' className={props.className}>
      <label htmlFor={'file_upload_'+props.id}>
        ファイルを選択
        <input type="file" style={style} name={props.id}  id={'file_upload_'+props.id} accept="image/jpeg" onChange={(event)=>ImageSelect(event)} data-id={props.imageId}/>
      </label>
    </button>
    {
      (srcValue === '' && props.imageSrc === '') || deleteImageFlag === false?
      <><span className='progress_none'>画像が選択されていません。</span><span className='progress_show'>loading...</span></>:
      <div className="thumbnail_area">
        <img id={props.id + '_thumb_img'} src={srcValue} alt=''/>
        <button className="image_delete" onClick={(event)=>ImageDelete(event,props.id)}><span>×</span></button>
      </div>
    }
    </>
  )
}

export default ImageSelectyButton