import React, { useCallback, useEffect, useState } from 'react'
import { adminLogout } from '../../reducks/admins/operations'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { RouteDir } from '../../common'

const Header = () =>
{
  const dispatch = useDispatch()

  const telnumberStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_tel.svg')`
  }

  const mailformStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_mail.svg')`
  }

  return(
    <>
    <footer>
      <div className="subline_1000">
        <aside className="footer_menu">
          {/* <ul>
            <li onClick={()=>dispatch(push(RouteDir))}>トップページ</li>
            <li onClick={()=>dispatch(push(RouteDir+'/howto'))}>ご利用について</li>
            <li onClick={()=>dispatch(push(RouteDir+'/commercial_transaction'))}>特定商取引法に基づく表記</li>
            <li onClick={()=>dispatch(push(RouteDir+'/privacy_policy'))}>プライバシーポリシー</li>
          </ul> */}
          <div className="media_area">
            {/* <span>公式メディア</span>
            <a href="https://www.instagram.com/ja_nagasaki_seihi_agriplus/" target="_blank">
              <img src={process.env.PUBLIC_URL + '/images/icon_instagram.svg'} alt="instagram" />
            </a>
            <a href="https://www.youtube.com/channel/UCgA2-pf8uF_jw3yY2vYLFWg" target="_blank">
              <img src={process.env.PUBLIC_URL + '/images/icon_youtube.svg'} alt="YouTube" />
            </a> */}
          </div>
        </aside>
        <div className="contact_infomation_area">
          <div className="telnumber_area">
            <p>お電話によるお問い合わせ</p>
            <p className="telnumber" style={telnumberStyle}>0957-48-2020</p>  {/* 変更必要 */}
            <p>10:00～18:00（定休日：土日祝）</p>  {/* 変更必要 */}
          </div>
          <div className="logo_image_area">
            <img src={process.env.PUBLIC_URL + '/images/logo_big_c.png'}  alt="" />  {/* 一般画面：フッター：ロゴ画像 */}
          </div>
          <div className="mailform_area">
            <p>メールによるお問い合わせ</p>
            <div className="mailform">
              <button className="btn_type_2" style={mailformStyle} onClick={()=>dispatch(push(RouteDir+'/contact'))}>コチラから</button>
            </div>
          </div>
        </div>
      </div>
      <div className='fp_menu'>
          <ul>
            <li onClick={()=>dispatch(push(RouteDir+'/howto'))}>ご利用ガイド</li>
            <li onClick={()=>dispatch(push(RouteDir+'/commercial_transaction'))}>特定商取引法に基づく表記</li>
            <li onClick={()=>dispatch(push(RouteDir+'/privacy_policy'))}>プライバシーポリシー</li>
          </ul>
        </div>
      <div className="copylight_area">
        <p>Copyright © 2022 株式会社ミナミハラLED All rights reserved.</p>  {/* 変更必要 */}
      </div>
    </footer>
    </>
  )
}

export default Header