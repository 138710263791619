import React from "react"
import DocumentMeta from 'react-document-meta'
import { deleteCart } from '../../reducks/carts/operations'
import { itemListLink } from '../../reducks/items/operations'
import { useDispatch, useSelector } from 'react-redux'
import { BreadCrumb, OrderFlow } from '../../components'
import { SiteTitle } from './common'
import { ImagesDir, RouteDir, ItemImageDir } from '../../common'
import { push } from "connected-react-router"

const CartList = () =>
{
  const dispatch = useDispatch()

  const items = useSelector(state => state.carts.list)

  const itemListLinkBtn = ()=>
  {
    dispatch(itemListLink())
  }

  const deleteConfirm = (cartId) =>
  {
    const result = window.confirm('買い物かごから削除してよろしいですか？')
    if(result)
    {
      dispatch(deleteCart(cartId))
    }
    else
    {
      return false
    }
  }

  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: '買い物カゴ',
      href: '/mypage/cart_list'
    }
  ]

  return(
    <DocumentMeta {...meta}>
      <div id="cart_list_page">
        <BreadCrumb
          floors = { Floors }
        />
        <main className="cart_list_content">
          <h1>買い物カゴ</h1>
          <OrderFlow
            activeKey = {0}
          />
          {Array.isArray(items) && items.length!==0?
            <>
            <p className="introduction">商品の数量を確認して「購入手続き」へお進みください。</p>
            <section className="list_type_2">
              <div className="scroll_wrap">
              <table>
                <thead>
                  <tr>
                    <th className="item_name_th">商品名</th>
                    <th>金額（税込）</th>
                    <th>個数</th>
                    <th className="delete_btn_th">&ensp;</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(items) && items.map((item, i) => (
                    <tr key={i}>
                      <td className="item_image_name_box">
                        <img src={item.path===null?process.env.PUBLIC_URL + '/images/noimage.jpg':ItemImageDir + item.path} alt={item.name} />
                        <div>
                          <p className="name">{item.name}</p>
                          <p className="standard">{item.standard}</p>
                        </div>
                      </td>
                      <td className="price_box">￥{Number(Math.floor(Number(item.price)*(1+(Number(item.tax_value)/100)))).toLocaleString()}</td>
                      <td className="quantity_box">
                        {item.quantity}個
                      </td>
                      <td className="delete_btn_box">
                        <button className="delete_btn" onClick={()=>deleteConfirm(item.cart_id)}>削除</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
              <p className="sp_txt">横スクロールできます。</p>
              <div className="btn_area">
                <button className="item_list_link_btn" onClick={()=>itemListLinkBtn()}>買い物を続ける</button>
                <button className="order_post_info_link_btn" onClick={()=>dispatch(push(RouteDir+'/mypage/add_order_post_address'))}>購入手続きへ</button>
              </div>
            </section>
            </>
          :
          <p>買い物かごに何も入っていません。</p>
          }
        </main>
      </div>
    </DocumentMeta>
  )
}

export default CartList