import React, { useCallback, useState } from "react"
import { updateMemberPassword } from '../../reducks/members/operations'
import { useDispatch, useSelector } from 'react-redux'
import DocumentMeta from 'react-document-meta'
import { BreadCrumb } from '../../components'
import { SiteTitle } from './common'

const Reissue = (props) =>
{
  const dispatch = useDispatch()

  //シリアルナンバーをURLパラメータから取得
  const pageSerial = props.match.params.serial

  const ReissueMemberStatus = useSelector(state => state.members.reissueMailAddress)

  //パスワード入力
  const [passwordValue, setPasswordValue] = useState('')
  const inputPasswordValue = useCallback((event) =>
  {
    inputValueCheck()
    setPasswordValue(event.target.value)
  }, [setPasswordValue])

  //入力値が入っているかの確認
  const inputValueCheck = () =>
  {
    const inputValueOfMailaddress = document.getElementsByName('password')

    if(inputValueOfMailaddress[0].value !== '')
    {
      document.getElementById('send_btn').classList.remove('desabled')
    }
    else
    {
      document.getElementById('send_btn').classList.add('desabled')
    }
  }

  //送信ボタン押下時の処理
  const sendFormData = () =>
  {
    //form情報の取得
    const formElement = document.getElementById('send_form')
    const formData = new FormData(formElement);

    //formkeyの追加
    formData.append('mail_address',ReissueMemberStatus)
    formData.append('serial',pageSerial)
    formData.append('formkey','updatekey')
    dispatch(updateMemberPassword(formData))
  }

  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: 'パスワード再設定',
      href: '/reissue'
    }
  ]

  return(
    <DocumentMeta {...meta}>
      <div id="signup_application_page">
        <BreadCrumb
          floors = { Floors }
        />
        <main className="signup_application_content">
          <div className="subline_500">
            <h1>パスワード再設定</h1>
            <section className="form_type_2">
              <p>再設定するパスワードを入力してください。</p>
              <form encType="multipart/form-data" method="post" id="send_form" onSubmit={(e)=>e.preventDefault()}>
                <dl>
                  <dt>パスワード</dt>
                  <dd>
                    <input
                      type = "text"
                      name = {'password'}
                      value = {passwordValue}
                      onChange = {inputPasswordValue}
                    />
                  </dd>
                </dl>
              </form>
              <div className="button_area">
                <button id = "send_btn" className="desabled" onClick={()=>sendFormData()}>再設定する</button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default Reissue