import React, { useCallback, useState } from "react"
import { insertDeliveryAddress } from '../../reducks/members/operations'
import DocumentMeta from 'react-document-meta'
import { useDispatch, useSelector } from 'react-redux'
import { RouteDir, JsDir } from "../../common"
import { BreadCrumb } from '../../components'
import { SiteTitle } from './common'
import importScript from '../../components/hooks/ImportScript'

const DeliveryAddressAdd = (props) =>
{
  // svg操作のjsの読み込み
  importScript(JsDir + '/ajaxzip3.js')

  const dispatch = useDispatch()

  //姓入力
  const [familyNameValue, setFamilyNameValue] = useState('')
  const inputFamilyNameValue = useCallback((event) =>
  {
    inputValueCheck()
    setFamilyNameValue(event.target.value)
  }, [setFamilyNameValue])

  //名入力
  const [firstNameValue, setFirstNameValue] = useState('')
  const inputFirstNameValue = useCallback((event) =>
  {
    inputValueCheck()
    setFirstNameValue(event.target.value)
  }, [setFirstNameValue])

  //セイ入力
  const [familyNameFuriganaValue, setFamilyNameFuriganaValue] = useState('')
  const inputFamilyNameFuriganaValue = useCallback((event) =>
  {
    inputValueCheck()
    setFamilyNameFuriganaValue(event.target.value)
  }, [setFamilyNameFuriganaValue])

  //メイ入力
  const [firstNameFuriganaValue, setFirstNameFuriganaValue] = useState('')
  const inputFirstNameFuriganaValue = useCallback((event) =>
  {
    inputValueCheck()
    setFirstNameFuriganaValue(event.target.value)
  }, [setFirstNameFuriganaValue])

  //郵便番号入力
  const [potalCodeValue, setPostalcodeValue] = useState('')
  const inputPostalCodeValue = useCallback((event) =>
  {
    inputValueCheck()
    setPostalcodeValue(event.target.value)
  }, [setPostalcodeValue])

  //住所入力
  const [addressValue, setAddressValue] = useState('')
  const inputAddressValue = useCallback((event) =>
  {
    inputValueCheck()
    setAddressValue(event.target.value)
  }, [setAddressValue])

  //電話番号入力
  const [telnumberValue, setTelnumberValue] = useState('')
  const inputTelnumberValue = useCallback((event) =>
  {
    inputValueCheck()
    setTelnumberValue(event.target.value)
  }, [setTelnumberValue])


  //入力値が入っているかの確認
  const inputValueCheck = () =>
  {
    const inputValueOfFamilyName = document.getElementsByName('family_name')
    const inputValueOfFirstName = document.getElementsByName('first_name')
    const inputValueOfFamilyNameFurigana = document.getElementsByName('family_name_furigana')
    const inputValueOfdFirstNameFurigana = document.getElementsByName('first_name_furigana')
    const inputValueOfPostalCode = document.getElementsByName('postal_code')
    const inputValueOfAddress = document.getElementsByName('address')

    if(inputValueOfFamilyName[0].value !== '' && inputValueOfFirstName[0].value !== '' && inputValueOfFamilyNameFurigana[0].value !== '' && inputValueOfdFirstNameFurigana[0].value !== '' && inputValueOfPostalCode[0].value !== '' && inputValueOfAddress[0].value !== '')
    {
      document.getElementById('insert_btn').classList.remove('desabled')
    }
    else
    {
      document.getElementById('insert_btn').classList.add('desabled')
    }
  }

  //送信ボタン押下時の処理
  const sendFormData = () =>
  {
    //form情報の取得
    const formElement = document.getElementById('insert_form')
    const formData = new FormData(formElement);

    //formkeyの追加
    formData.append('formkey','insertkey')
    dispatch(insertDeliveryAddress(formData))
  }
  //住所自動入力
  const {AjaxZip3} = window
  const postalAutoInput = ()=>
  {
    AjaxZip3.zip2addr('postal_code','','address','address')
    setTimeout(()=>{
      const addressElement = document.getElementsByName('address')
      const addressValue = addressElement[0].value
      setAddressValue(addressValue)
    },100)
  }
  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: 'お届け先新規登録',
      href: '/delivery_address_add'
    }
  ]

  return(
    <DocumentMeta {...meta}>
      <div id="delivery_address_add_page">
        <BreadCrumb
          floors = { Floors }
        />
        <main className="delivery_address_add_content form_type_1">
          <h1>お届け先新規登録</h1>
          <p className="introduction">追加したいお届け先情報をご入力ください。</p>
          <form encType="multipart/form-data" method="post" id="insert_form" onSubmit={(e)=>e.preventDefault()}>
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th>お届け先お名前<span className="required">必須</span></th>
                  <td>
                    <div className="family_name_area">
                      <span>姓</span>
                      <input
                        type = "text"
                        name = {'family_name'}
                        value = {familyNameValue}
                        onChange = {inputFamilyNameValue}
                      />
                    </div>
                    <div className="first_name_area">
                      <span>名</span>
                      <input
                        type = "text"
                        name = {'first_name'}
                        value = {firstNameValue}
                        onChange = {inputFirstNameValue}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>フリガナ<span className="required">必須</span></th>
                  <td>
                    <div className="family_name_area">
                      <span>セイ</span>
                      <input
                        type = "text"
                        name = {'family_name_furigana'}
                        value = {familyNameFuriganaValue}
                        onChange = {inputFamilyNameFuriganaValue}
                      />
                    </div>
                    <div className="first_name_area">
                      <span>メイ</span>
                      <input
                        type = "text"
                        name = {'first_name_furigana'}
                        value = {firstNameFuriganaValue}
                        onChange = {inputFirstNameFuriganaValue}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>郵便番号<span className="required">必須</span></th>
                  <td>
                    〒
                    <input
                      type = "text"
                      name = {'postal_code'}
                      value = {potalCodeValue}
                      onChange = {inputPostalCodeValue}
                    />
                    <button onClick={()=>postalAutoInput()}>郵便番号から検索</button>
                  </td>
                </tr>
                <tr>
                  <th>住所<span className="required">必須</span></th>
                  <td>
                    <input
                      type = "text"
                      name = {'address'}
                      value = {addressValue}
                      onChange = {inputAddressValue}
                    />
                  </td>
                </tr>
                <tr>
                  <th>電話番号</th>
                  <td>
                    <input
                      type = "text"
                      name = {'telnumber'}
                      value = {telnumberValue}
                      onChange = {inputTelnumberValue}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <div className="button_area">
          <button id = "insert_btn" className="desabled" onClick={()=>sendFormData()}>追加する</button>
          </div>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default DeliveryAddressAdd